<template>
  <div class="animated fadeIn">
    <b-nav tabs style="margin-bottom: -1px;">
      <b-nav-item
        :key="index"
        v-for="(item, index) in type_list"
        @click="changeType(item.index, item.min_day, item.max_day)"
        :active="selectedTabIndex == item.index"
        >{{ item.label }}</b-nav-item
      >
    </b-nav>
    <!-- <b-row>
      <b-col sm="12">
        <c-table
          :url="'CustomerWebService/get_overdue_repayment_list'"
          :per-page="10"
          :fields="columns"
          :columns="[
            'firstname',
            'lastname',
            'register',
            'phone',
            'payment',
            'payAmount',
            'payAmountTotal',
            'loanBalance',
            'interestAmount',
            'unduelossAmount',
            'totalUnduelossAmount',
            'sysUserName',
          ]"
          :filename="'Хугацаа хэтрэлт'"
          :startDate="minDate"
          :endDate="maxDate"
          hover
          bordered
          fixed
          caption=""
        ></c-table>
      </b-col>
    </b-row> -->


    <b-card>
      <loading
          :active.sync="isLoadingData"
          :is-full-page="false"
          :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity"
          :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"
        ></loading>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity"
          :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"
        ></loading>
        <div class="mb-3">
          <download-excel
            class="btn btn-primary pull-right ml-3"
            :fetch="fetchData"
            :fields="download.header"
            :before-generate="startDownload"
            :before-finish="finishDownload"
            :name="'Зээлийн түүх.xls'"
            v-if="items != undefined && items.length > 0"
          >
            <b-spinner small type="grow" v-show="download.loading"></b-spinner>
            <span v-show="download.loading" class="ml-1">Уншиж байна</span>
            <i class="fa fa-download" v-show="!download.loading"></i>
            <span v-show="!download.loading" class="ml-1">Excel татах</span>
          </download-excel>

          <div class="pull-right">
            <b-form-select
              v-model="query.per_page"
              :options="[5, 10, 25, 100]"
              v-if="items != undefined && items.length > 0"
            >
            </b-form-select>
          </div>
          <b-row>
 
            <b-col class="col-md-8">
              <multiselect
                v-model="selected_status"
                :options="loan_types"
                :searchable="true"
                track-by="value"
                placeholder="Зээлийн төрөл"
                :multiple="false" 
                :close-on-select="false"
                :preserve-search="true" 
                :option-height="10"
                label="text"
                group-values="options"
                group-label="label"
              >
              <template slot="singleLabel" slot-scope="props"><span class="option__text">{{ props.option.text }}</span></template>
              <template slot="option" slot-scope="props">
                <div class="option__desc"><span class="option__text">{{ props.option.text }}</span></div>
              </template>
              </multiselect>
            </b-col>

            <b-col class="col-md-4">
              <b-form @submit.stop.prevent="submitSearch">
                <b-input-group>
                  <b-input-group-prepend is-text
                    ><i class="fa fa-search"></i
                  ></b-input-group-prepend>
                  <b-form-input
                    v-model="search"
                    placeholder="Хайх"
                  ></b-form-input>
                  <b-input-group-append
                    ><b-button variant="primary" type="submit"
                      >Хайх</b-button
                    ></b-input-group-append
                  >
                  <b-input-group-append
                    ><b-button variant="secondary" @click="loadData"
                      ><i class="fa fa-refresh"></i
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form>
            </b-col>

          </b-row>
        </div>
        <div
          class="table-responsive"
          v-if="items != undefined && items.length > 0"
        >
          <b-table
            id="filter-table"
            stacked="md"
            @sort-changed="sortingChanged"
            hover
            bordered
            small
            responsive="sm"
            :items="items"
            :fields="columns"
            no-local-sorting
          >

          <template v-slot:cell(nicename)="data">
            {{
              data.item.lastname.substr(0, 1) + "." + data.item.firstname
            }}
          </template>

          <template v-slot:cell(file)="data">
            <b-button
              variant="primary"
              size="sm"
              @click="getOverdueJudgeHTML(data.item)"
              style="width: 50px;"
              v-if="
                checkPermission(
                  'admin.loan-repayment-overdue.create-statement-judge'
                )
              "
              ><i class="fa fa-eye"></i
            ></b-button>
            <b-button
              class="ml-1"
              variant="primary"
              size="sm"
              @click="getOrderJudgeModal(data.item)"
              v-if="
                checkPermission(
                  'admin.loan-repayment-overdue.create-statement-judge'
                )
              "
              >нэхэмжлэх</b-button
            >
            <b-button
              class="ml-1"
              variant="primary"
              size="sm"
              @click="getAccreditedJudgeModal(data.item)"
              v-if="
                checkPermission(
                  'admin.loan-repayment-overdue.create-statement-judge'
                )
              "
              >итгэмжлэл</b-button
            >
            <b-button
              class="ml-1"
              variant="primary"
              size="sm"
              @click="getCalculationJudgeModal(data.item)"
              v-if="
                checkPermission(
                  'admin.loan-repayment-overdue.create-statement-judge'
                )
              "
              >тооцоолол</b-button
            >
          </template>
          <template v-slot:cell(sysUserName)="data">
            <strong class="text-primary">
              {{ data.item.sysUserName }}
            </strong>
          </template>
          <template v-slot:cell(overdueDay)="data">
            <strong class="text-danger">
              {{ data.item.overdueDay }} өдөр</strong
            >
          </template>

          <template v-slot:cell(fbToken)="data">
            <b-button
              variant="primary"
              size="sm"
              @click="getLastWorkData(data.item)"
              style="width: 50px;"
              ><i class="fa fa-eye"></i>
              {{ data.item.fbToken ? "FB" : "" }}</b-button
            >
          </template>

          <template v-slot:cell(loanTypeCodeList)="data">
            <b-badge
              :key="index"
              v-for="(item, index) in data.item.loanTypeCodeList"
              variant="info mr-1"
              >{{ $store.getters.loan_type[item] }}</b-badge
            >
          </template>

          <template v-slot:cell(index)="data">
            {{ data.index + pagination.from }}
          </template>
          <template v-slot:cell(status_name)="data">
            <span
              class="badge"
              :class="getClassByStatus(data.item.status_name)"
              >{{ data.item.status_name }}</span
            >
          </template>
            
          <template v-slot:cell(loan_amount)="data">
            {{ data.item.loan_amount|currecryZero }}
          </template>
          <template v-slot:cell(actions)="data">
            <b-button-group
              v-if="data.index <= pagination.to - pagination.from"
            >
              <b-button
                size="sm"
                variant="primary"
                @click="showNote(data.item.customerId)"
              >
                Тэмдэглэл
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                :to="{
                  name: 'admin.customer.detail',
                  params: { id: data.item.customerId }
                }"
              >
                <i class="fa fa-id-card"></i>
              </b-button>
            </b-button-group>
          </template>
          </b-table>

          <div slot="footer" class="mb-0 mt-3">
            <nav class="pull-right" v-if="pagination.total > query.per_page">
              <b-pagination
                class="mb-0"
                :total-rows="pagination.total"
                :per-page="query.per_page"
                v-model="query.page"
              />
            </nav>
            <p class="mb-0">
              {{ pagination.total }} өгөгдлөөс
              {{ pagination.to ? pagination.from : 0 }} -
              {{ pagination.to }} өгөгдөл харагдаж байна.
            </p>
          </div>
        </div>

        <b-alert variant="warning" v-else show class="mb-0">
          Мэдээлэл байхгүй байна
        </b-alert>
      </b-card>
      <b-modal ref="note-modal" title="Тэмдэглэл" hide-footer size="xl">
        <Note :customerId="show_note_customer_id"></Note>
      </b-modal>

      <b-modal
        ref="fb-modal"
        hide-footer
        :title="modalData.nicename + ' - ' + modalData.phone"
      >
        <div class="d-block">
          <div class="text-center" v-if="modalData.fb_id != ''">
            <b-img
              alt="placeholder"
              :src="
                'http://graph.facebook.com/' +
                  modalData.fb_id +
                  '/picture?type=large'
              "
            ></b-img>
            <h2 class="mt-0">{{ modalData.fb_name }}</h2>
            <hr />
          </div>
          <b-media v-if="modalData.work.name != ''">
            <p class="mt-0 mb-0" style="font-size:16px;">
              НДШ төлсөн сүүлийн байгуулга
            </p>
            <h3>
              <strong>{{ modalData.work.name }}</strong> -
              {{ modalData.work.year }} оны {{ modalData.work.month }} сар
            </h3>
          </b-media>
        </div>
      </b-modal>
      <b-modal ref="judge-modal" hide-footer title="Шүүхийн нэхэмжлэх">
        <div class="d-block">
          <div class="text-left" v-if="modalData.fb_id != ''">
            <div class="form-group">
              <label for="judge_select">Нэхэмжлэх гаргах шүүх</label>
              <select
                name=""
                id="judge_select"
                class="form-control"
                v-model="selected_judge"
              >
                <option :value="null" selected disabled>Сонгоно уу</option>
                <option
                  v-for="(item, index) in judge_list"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <button
              class="btn btn-primary text-center"
              @click="getOrderJudgeHTML"
            >
              Хэвлэх
            </button>
          </div>
        </div>
      </b-modal>

      <b-modal ref="judge-accredited-modal" hide-footer title="Шүүхийн итгэмжлэл">
        <div class="d-block">
          <div class="text-left" >
            <div class="form-group">
              <label for="judge_select">Итгэмжлэл гаргах шүүх</label>
              <select
                name=""
                id="judge_select"
                class="form-control"
                v-model="selected_accredited_judge"
              >
                <option :value="null" selected disabled>Сонгоно уу</option>
                <option
                  v-for="(item, index) in judge_list"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
              <hr>
              <b-form-group
                  id="input-group-lastname"
                  label="Итгэмжлэх ажилтан:"
                  label-for="input-lastname"
                >
                  <b-form-textarea
                    id="input-lastname"
                    v-model="accredited_user"
                    type="text"
                    placeholder="Бичнэ үү"
                  ></b-form-textarea>
                </b-form-group>
            </div>
            <button
              class="btn btn-primary text-center"
              @click="getAccreditedJudgeHTML"
            >
              Хэвлэх
            </button>
          </div>
        </div>
      </b-modal>

      <b-modal ref="judge-calculation-modal" hide-footer title="Цахим зээлийн тооцоолол">
        <div class="d-block">
          <div class="text-left" >
            <div class="form-group">
              <b-form-group
                  id="input-group-lastname"
                  label="Тооцоолол гаргасан ажилтан:"
                  label-for="input-lastname"
                >
                  <b-input class="mb-3" v-model="calculation_position" placeholder="Албан тушаал"></b-input>
                  <b-input v-model="calculation_name" placeholder="Нэр"></b-input>
              </b-form-group>
              <hr>
            </div>
            <button
              class="btn btn-primary text-center"
              @click="getCalculationJudgeHTML"
            >
              Хэвлэх
            </button>
          </div>
        </div>
      </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
export default {
  name: "LoanRepaymentsOverdue.list",
  
  data: function() {
    return {
      isLoading: false,
      isLoadingData: false,
      show_note_customer_id: 0,
      selectedTabIndex: 0,
      minDate: moment()
        .subtract(10000, "days")
        .format("YYYY-MM-DD"),
      maxDate: moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
      modalData: {
        fb_id: null,
        fb_name: "",
        name: "",
        register: "",
        work: {
          year: "",
          month: "",
          name: "",
        },
      },
      columns: [
        { label: "№", key: "index", class: "text-center" },
        {
          label: "Нэр",
          key: "nicename",
          sortable: true,
          sort_key: "firstname",
        },
        {
          label: "Регистр",
          key: "registerNumber",
          sortable: true,
          sort_key: "register",
        },
        {
          label: "Утас",
          key: "phoneNumber",
          sortable: true,
          sort_key: "phone",
        },
        {
          label: "Утас 2",
          key: "emergencyNumber",
          sortable: true,
          sort_key: "phone2",
        },
        {
          label: "Төлөх огноо",
          key: "paymentDate",
          sortable: true,
          sort_key: "payment",
        },
        { label: "Хэтэрсэн хоног", key: "overdueDay" },
        {
          label: "Төлөх дүн",
          key: "payAmount",
          sortable: true,
          sort_key: "payAmount",
          class: "text-right",
        },
        {
          label: "Нийт төлөх",
          key: "payAmountTotal",
          sortable: "true",
          class: "text-right"
        },
        {
          label: "Зээлийн төрөл",
          key: "loanTypeCodeList",
        },
        { label: "Хариуцсан ажилтан", key: "sysUserName" },
        { label: "Нэмэлт", key: "fbToken", class: "text-center" },
        { label: "Шүүх", key: "file", class: "text-center" },
        // { label: 'Үлдэгдэл', key: 'loanBalance', sortable: true, sort_key:'loanBalance', class:"text-right" },
        // { label: 'Хүү', key: 'interestAmount', sortable: true, sort_key:'interestAmount', class:"text-right" },
        // { label: 'Алданги', key: 'unduelossAmount', sortable: true, sort_key:'unduelossAmount', class:"text-right" },
        // { label: 'Торгууль', key: 'totalUnduelossAmount', sortable: true, sort_key:'totalUnduelossAmount', class:"text-right" },
        // { label: 'Нийт дүн', key: 'totalAmount', sortable: true, sort_key:'totalAmount', class:"text-right" }
      ],
      // columns: [
      //   { label: "№", key: "index", class: "text-center" },
      //   {
      //     label: "Овог",
      //     key: "lastname",
      //     sortable: true,
      //     sort_key: "lastname"
      //   },
      //   {
      //     label: "Нэр",
      //     key: "firstname",
      //     sortable: true,
      //     sort_key: "firstname"
      //   },
      //   {
      //     label: "Регистр",
      //     key: "regnum",
      //     sortable: true,
      //     sort_key: "regnum"
      //   },
      //   {
      //     label: "Үйл ажиллагааны чиглэл",
      //     key: "activity_type"
      //   },
      //   {
      //     label: "Хүсэж буй зээлийн дүн",
      //     key: "loan_amount"
      //   },
      //   {
      //     label: "Эдийн засагч",
      //     key: "e_firstname",
      //     sortable: true,
      //     sort_key: "e_name"
      //   },
      //   {
      //     label: "Хүсэлт гаргасан огноо",
      //     key: "sent_date",
      //     sortable: true,
      //     sort_key: "created_date"
      //   },
      //   {
      //     label: "Төлөв",
      //     key: "status_name",
      //     sortable: true,
      //     sort_key: "status"
      //   },
      //   {
      //     label: "Тайлбар",
      //     key: "description"
      //   },
      //   { label: "Үйлдэл", key: "actions", class: "text-center" }
      // ],
      type_list: [
        {
          index: 0,
          min_day: 1,
          max_day: 10000,
          label: "Бүгд",
        },
        {
          index: 1,
          min_day: 1,
          max_day: 15,
          label: "15 хүртэл хоног",
        },
        {
          index: 2,
          min_day: 16,
          max_day: 30,
          label: "16-30 хоног",
        },
        {
          index: 3,
          min_day: 31,
          max_day: 60,
          label: "31-60 хоног",
        },
        {
          index: 4,
          min_day: 61,
          max_day: 90,
          label: "61-90 хоног",
        },
        {
          index: 5,
          min_day: 91,
          max_day: 180,
          label: "91-180 хоног",
        },
        {
          index: 6,
          min_day: 181,
          max_day: 365,
          label: "181-365 хоног",
        },
        {
          index: 7,
          min_day: 366,
          max_day: 10000,
          label: "366+ хоног",
        },
      ],
      download: {
        loading: false,
        header: {}
      },
      query: {
        id: 0,
        page: 1,
        per_page: 10,
        orderDirection: "DESC",
        orderBy: "id",
        search: "",
        loan_type: '',
        columns: 'firstname,lastname,register',
        loan_types: []
          //   'phone',
          //   'payment',
          //   'payAmount',
          //   'payAmountTotal',
          //   'loanBalance',
          //   'interestAmount',
          //   'unduelossAmount',
          //   'totalUnduelossAmount',
          //   'sysUserName',
          // ]
      },
      payment: {
        over: "0",
        under: "0",
        undueLoss: "0"
      },
      pagination: {
        total: 0,
        from: 0,
        to: 0
      },
      orderDesc: false,
      items: [],
      selected_status: "",
      selected_employee: null,
      loan_types: [],
      employees: [],
      search: "",
      judge_list: [],
      selected_judge: null,
      selected_customer: null,
      selected_accredited_judge: null,
      accredited_user: null,
      calculation_position: 'Нягтлан бодогч',
      calculation_name: 'И.Уринболор',
      isCalcInterestStopped: false
    };
  },
  created: function() {
    // if( )) {
    //     this.$data.columns.push(

    //     );
    //     this.$data.columns.push(
    //       { label: 'PDF1',key: 'noLoanHtml',class:"text-center"}
    //     );
    // }
    var _buttons = [];
    if (this.checkPermission("admin.loan-repayment-overdue.note")) {
      _buttons.push({
        icon: "",
        label: "Тэмдэглэл",
        variant: "outline-primary",
        callback: this.showNote,
      });
    }

    if (this.checkPermission("admin.customer.detail")) {
      _buttons.push({
        icon: "fa fa-id-card",
        label: "",
        title: "Хэрэглэгчийн дэлгэрэнгүй",
        variant: "outline-primary",
        route: "admin.customer.detail",
        route_param: "customerId",
      });
    }

    if (_buttons.length > 0) {
      this.$data.columns.push({
        label: "Үйлдэл",
        key: "actions",
        buttons: _buttons,
        class: "text-center",
      });
    }
    this.loadJudge();

    this.initData();
    this.$data.query.id = this.customerId;
    this.$data.download.header = {};
    for (let i in this.$data.columns) {
      let _field = this.$data.columns[i];
      if (_field.label != "Үйлдэл")
        this.$data.download.header[_field.label] = _field.key;
    }

    let loan_tag_ids = []
    for (var i in this.$data.form.loan_tags) {
      loan_tag_ids.push(this.$data.form.loan_tags[i].id)
    }

    this.$data.download.header["Эдийн засагч"] = "e_firstname";
    this.loadData();
  },

  watch: {
    query: {
      handler(query) {
        this.loadData();
      },
      deep: true
    },
    selected_status: {
      handler(selected_status) {
        // let S = []
        // for (let i in selected_status) {
        //   S.push( selected_status[i])
        // }
        

        if (selected_status) {
          this.$data.query.loan_type = selected_status.value;
          this.$data.query.page=1;
        }
        else this.$data.query.loan_type = "";
        alert(JSON.stringify(this.$data.query))
        this.loadData();
      },
      deep: true
    },

    minDate: {
      handler(startDate) {
        this.$data.query.page = 1;
        this.loadData();
      },
      deep: true,
    },
    maxDate: {
      handler(endDate) {
        this.$data.query.page = 1;
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    customLabel ({ text }) {
      return `${text}`
    },

    getClassByStatus: function(status) {
      return {
        Илгээсэн: "badge-warning text-white",
        Баталгаажсан: "badge-success",
        Татгалзсан: "badge-danger",
        "Судалж байгаа": "badge-info text-white",
        Шинэ: "badge-secondary",
        "Цуцалсан": "badge-danger",
        "Зээл олгосон": "badge-success",
        "Муу зээлийн түүхтэй": 'badge-danger',
        "Хүлээж авсан": 'badge-info',
        "Шалгаж байгаа": 'badge-warning'
      }[status];
    },
    initData: function() {
      this.$data.loan_types = this.$store.getters.loan_type_options
      // alert(JSON.stringify(this.$store.getters.loan_type_options[0]))
      // for(let i in this.$store.getters.loan_type_options) {
      //   let _item = this.$store.getters.loan_type_options[i]
      //   let options = []
      //   for(let j in _item['options']) {
      //     let _option = _ite['options'][j]
      //     options.push({
      //       id: i,
      //       name: _option['value'], 
      //       text: _option['text']
      //     })
      //   } 

      //   this.$data.loan_types.push({
      //     'label': _item['label'],
      //     o
      //   })
      // }
    },

    loadData: function() {
      this.checkSession();
      this.$data.isLoadingData = true;

      this.$data.query.start_at = this.$data.minDate
      this.$data.query.end_at = this.$data.maxDate

      this.$http
        .get(this.$config.API_URL + "CustomerWebService/get_overdue_repayment_list", {
          params: this.$data.query,
          headers: this.$store.getters.httpHeader,
          emulateJSON: true
        })
        .then(
          (response) => {
            this.isLoadingData = false;
            this.$data.items = response.body.data;
            this.$data.pagination.total = response.body.total;
            this.$data.pagination.from = response.body.from;
            this.$data.pagination.to = response.body.to;
          },
          (response) => {
            this.isLoadingData = false;
          }
        );
    },

    sortingChanged(ctx) {
      let _field = "";
      for (let i in this.fields) {
        if (this.fields[i].key == ctx.sortBy) {
          this.$data.query.orderBy = this.fields[i].sort_key;
          break;
        }
      }
      this.$data.query.orderDirection = ctx.sortDesc ? "ASC" : "DESC";
    },

    submitSearch: function() {
      this.$data.query.search = this.$data.search;
    },

    // async fetchData() {
    //   if (this.download.loading) {
    //     this.download.loading = true;
    //     const response = await axios.get(
    //       this.$config.APP_NUMUR_URL + "api/business/loan_request_list",
    //       {
    //         params: {
    //           page: 1,
    //           per_page: 0,
    //           orderDirection: this.$data.query.orderDirection,
    //           orderBy: this.$data.query.orderBy,
    //           search: this.$data.query.search,
    //           e_id: this.$data.query.e_id,
    //           status: this.$data.query.status
    //         },
    //         emulateJSON: true
    //       }
    //     );
    //     if (response.data.status == 500) {
    //       this.showToast(
    //         "Алдаа",
    //         "Алдаа гарсан байна. Дахин оролдоно уу",
    //         "danger"
    //       );
    //       this.download.loading = false;
    //       return [];
    //     }

    //     let _result = [];
    //     for (let i in response.data.data) {
    //       let _item = response.data.data[i];
    //       _item.index = parseInt(i) + 1;
    //       _item.closingAmount = "";
    //       _result.push(_item);
    //     }
    //     return _result;
    //   }
    //   return [];
    // },

    async fetchData(){
      if(this.download.loading) {
        this.download.loading = true;
        

        const response = await axios.get(this.$config.API_URL + 'CustomerWebService/get_overdue_repayment_list',{
          params: {
              page: 1,
              per_page: 0,
              orderDirection: this.$data.query.orderDirection,
              orderBy: this.$data.query.orderBy,
              search: this.$data.query.search,
              columns: this.$data.query.columns,
              loan_type: this.$data.query.loan_type,
              start_at: this.$data.query.start_at,
              end_at: this.$data.query.end_at

            },

          headers: this.$store.getters.httpHeader,
          emulateJSON: true
        });
        if(response.data.status == 500) {
          this.showToast('Алдаа', 'Алдаа гарсан байна. Дахин оролдоно уу', 'danger')
          this.download.loading = false;
          return []
        } 
        let _result = []
        for(let i in response.data.data) {
            let _item = response.data.data[i]
            _item.index = parseInt(i)+1
            if(_item.transactionType == 'LOAN') {
                _item.amount_loan = _item.amount;
                _item.amount_repayment = '';
            } else {
                _item.amount_repayment = _item.amount;
                _item.amount_loan = ''
                _item.bankName = ''
            }
          _result.push(_item)
        }
        return _result
      }
      return []
    },

    startDownload() {
      this.download.loading = true;
    },
    finishDownload() {
      this.download.loading = false;
    },

    showNote(customerId) {
      this.$data.show_note_customer_id = customerId;
      this.$refs["note-modal"].show();
    },
    hideNote() {
      this.$refs["note-modal"].hide();
    },
    changeType(index, minDay, maxDay) {
      this.$data.selectedTabIndex = index;
      this.$data.minDate = moment()
        .subtract(maxDay, "days")
        .format("YYYY-MM-DD");
      this.$data.maxDate = moment()
        .subtract(minDay, "days")
        .format("YYYY-MM-DD");
    },

    getLastWorkData: function(item) {
      this.isLoading = true;
      this.$data.modalData.work.name = "";
      this.$data.modalData.work.year = "";
      this.$data.modalData.work.month = "";
      this.$data.modalData.nicename =
        item.lastname.substr(0, 1) + "." + item.firstname;
      this.$data.modalData.phone = item.phoneNumber;
      this.$http
        .get(
          this.$config.API_URL + "CustomerWebService/get_worker_last_position",
          {
            params: { id: item.customerId },
            emulateJSON: true,
            headers: this.$store.getters.httpHeader,
          }
        )
        .then(
          (response) => {
            //console.log(response);
            if (response.body.responseResultType == "SUCCESS") {
              this.$data.modalData.work.name = response.body.workName;
              this.$data.modalData.work.year = response.body.year;
              this.$data.modalData.work.month = response.body.month;
            }
            this.getFbData(item);
          },
          (response) => {
            this.isLoading = false;
            alert("Мэдээлэл байхгүй байна");
          }
        );
    },
    loadJudge: function() {
      this.$http
        .get(this.$config.API_URL + "StatementWebService/get_judge_list", {
          params: {},
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            //console.log(response);
            this.isLoading = false;
            this.$data.judge_list = response.body.data;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },

    getOverdueJudgeHTML: function(item) {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "StatementWebService/overdue_judge", {
          params: { id: item.customerId },
          emulateJSON: true,
          headers: this.$store.getters.httpHeader,
        })
        .then(
          (response) => {
            this.isLoading = false;
            var mywindow = window.open("", "Print", "_blank");
            mywindow.document.write(response.body.successData);
            // mywindow.document.close();
            mywindow.print()

            //console.log(response);
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    getOrderJudgeModal: function(item) {
      this.$data.selected_customer = item.customerId;
      this.$refs["judge-modal"].show();
    },
    getOrderJudgeHTML: function(item) {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "StatementWebService/order_judge", {
          params: {
            id: this.$data.selected_customer,
            lid: this.$data.selected_judge,
          },
          emulateJSON: true,
          headers: this.$store.getters.httpHeader,
        })
        .then(
          (response) => {
            this.isLoading = false;
            var mywindow = window.open("", "Print", "_blank");
            mywindow.document.write(response.body.successData);
            mywindow.print()
            // mywindow.document.close();
            // mywindow.open("");

            //console.log(response);
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    getFbData: function(item) {
      this.isLoading = true;
      this.$data.modalData.fb_id = "";
      this.$data.modalData.fb_name = "";
      this.$http
        .get(this.$config.FB_GRAPH_URL + item.fbToken, {
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.isLoading = false;
            this.$data.modalData.fb_id = response.body.id;
            this.$data.modalData.fb_name = response.body.name;
            this.$refs["fb-modal"].show();
          },
          (response) => {
            this.isLoading = false;
            this.showToast(
              "Анхаар",
              "FB мэдээлэл харуулах боломжгүй байна",
              "warning"
            );
            if (this.$data.modalData.work.name != "") {
              this.$refs["fb-modal"].show();
            }
          }
        );
    },
    getAccreditedJudgeModal: function(item) {
      this.$data.selected_customer = item.customerId;
      this.$refs["judge-accredited-modal"].show();
    },
     getCalculationJudgeModal: function(item) {
      this.$data.selected_customer = item.customerId;
      this.$refs["judge-calculation-modal"].show();
    },
    getAccreditedJudgeHTML: function(item) {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "StatementWebService/accredited_judge", {
          params: {
            id: this.$data.selected_customer,
            lid: this.$data.selected_accredited_judge,
            accredited_user: this.$data.accredited_user
          },
          emulateJSON: true,
          headers: this.$store.getters.httpHeader,
        })
        .then(
          (response) => {
            this.isLoading = false;
            var mywindow = window.open("", "Print", "_blank");
            mywindow.document.write(response.body.successData);
            mywindow.print()
            // mywindow.document.close();
            // mywindow.open("");

            //console.log(response);
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    getCalculationJudgeHTML: function(item) {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "StatementWebService/calculcation_html", {
          params: {
            id: this.$data.selected_customer,
            position: this.$data.calculation_position,
            name: this.$data.calculation_name
          },
          emulateJSON: true,
          headers: this.$store.getters.httpHeader,
        })
        .then(
          (response) => {
            this.isLoading = false;
            var mywindow = window.open("", "Print", "_blank");
            mywindow.document.write(response.body.successData);
            mywindow.print()
            // mywindow.open("");

            //console.log(response);
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
